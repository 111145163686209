
import { useMarketStore } from '~/stores/market'
export default {
  name: 'CartLinkAnonim',
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
}
